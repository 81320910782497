import axios from "axios";
import router from "@/router";

axios.defaults.timeout = process.env.VUE_APP_API_TIMEOUT;
// 生产环境通过nginx解决跨域问题
axios.defaults.baseURL = `${process.env.VUE_APP_API_BASE_PATH}api/${process.env.VUE_APP_API_VERSION}`;
// 每次调用 API 自动携带 Cookie
axios.defaults.withCredentials = true;

axios.interceptors.request.use(
  request => {
    request.headers["content-type"] = "application/json";
    return request;
  },
  error => {
    error.message = "服务器或网络异常";
    return Promise.reject(error.message);
  }
);

axios.interceptors.response.use(
  response => {
    return response.data;
  },
  error => {
    let errorResponse = error.response;
    let status = errorResponse.status;
    if (status === 401) {
      router.push({ name: "login" });
    } else if (status === 403) {
      router.push({ name: "nopage" });
    } else if (status === 423) {
      router.push({
        name: "noreg",
        params: { baseCode: errorResponse.data }
      });
    }
    error.message = errorResponse.data;
    return Promise.reject(error);
  }
);

export function get(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .get(url, { params })
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

export function post(url, data) {
  return new Promise((resolve, reject) => {
    axios
      .post(url, data)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}
