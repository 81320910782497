import Vue from "vue";
import Vuetify from "vuetify/lib";
import zhHans from "vuetify/lib/locale/zh-Hans";
import "@mdi/font/css/materialdesignicons.min.css";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "mdi"
  },
  theme: { dark: false },
  lang: {
    locales: { zhHans },
    current: "zhHans"
  }
});
